<template>
  <b-card>
    <h1>{{produto.name}}</h1>
    <b-form>
      <b-row class="mt-2">
        <b-col cols="12" md="6">
          <b-form-group label="">
            <b-form-input v-model="produto.name" />
          </b-form-group>
        </b-col>  
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import axios from 'axios'
import { baseApiUrl } from '@/global'

export default {
  data() {
    return {
      produto :{}
    }
  },
  methods: {
    getProduto() {
      axios.post(`${baseApiUrl}/produto`, {
        id: this.$route.params.id
      }).then(res => {
        if( res.data.success) {
          this.produto = res.data.data
        }
      })
    }
  },
  mounted() {
    this.getProduto()
  }

}
</script>

<style>

</style>